
	import { Component, Inject, Vue } from "vue-property-decorator";

	import TagModal from "./modal/TagModal.vue";
	import type { TagModel } from "@common/models/kiosk";

	@Component({ components: { TagModal } })
	export default class extends Vue {
		$refs!: {
			modal: TagModal;
		};

		@Inject() public readonly alert!: AlertMethod;
		@Inject() public readonly confirm!: ConfirmMethod;

		protected data: TagModel[] | null = null;

		protected tag: string = "";

		beforeMount(): void {
			this.load();
		}

		private async load(): Promise<void> {
			this.data = null;
			try {
				this.data = await post<TagModel[]>("/api/tag");
			} catch(e: unknown) {
				if(e instanceof Error) await this.alert(e.message || "聯繫伺服器逾時", "讀取失敗", "error");
				this.$router.back();
			}
		}

		protected get tags(): string[] {
			return this.data?.map(t => t.Name) ?? [];
		}

		protected async add(): Promise<void> {
			await post<boolean>("/api/tag/add", { Name: this.tag });
			this.tag = "";
			await this.load();
		}

		protected async del(t: TagModel): Promise<void> {
			if(!await this.confirm(`確定刪除「${t.Name}」標籤嗎？`, "刪除標籤", "danger")) return;
			await post<boolean>("/api/tag/delete", { Id: t.Id });
			this.data!.splice(this.data!.indexOf(t), 1);
		}

		protected edit(t: TagModel): void {
			this.$refs.modal.show(t);
		}
	}
