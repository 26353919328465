var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Inject, Vue } from "vue-property-decorator";
import { TagType } from "@common/models/kiosk";
import TagModal from "./modal/TagModal.vue";
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.data = null;
        this.tag = "";
    }
    beforeMount() {
        this.load();
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            try {
                this.data = yield post("/api/tag", { Type: TagType.Kiosk });
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message || "聯繫伺服器逾時", "讀取失敗", "error");
                this.$router.back();
            }
        });
    }
    get tags() {
        var _a, _b;
        return (_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.map(t => t.Name)) !== null && _b !== void 0 ? _b : [];
    }
    add() {
        return __awaiter(this, void 0, void 0, function* () {
            yield post("/api/tag/add", { Name: this.tag, Type: TagType.Kiosk });
            this.tag = "";
            yield this.load();
        });
    }
    del(t) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!(yield this.confirm(`確定刪除「${t.Name}」標籤嗎？`, "刪除標籤", "danger")))
                return;
            yield post("/api/tag/delete", { Id: t.Id });
            this.data.splice(this.data.indexOf(t), 1);
        });
    }
    edit(t) {
        this.$refs.modal.show(t);
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Inject()
], default_1.prototype, "confirm", void 0);
default_1 = __decorate([
    Component({ components: { TagModal } })
], default_1);
export default default_1;
