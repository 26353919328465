var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { now, pad } from "@common/date";
import { MerchantKioskType } from "@common/models/kiosk";
import { KioskTransactionType } from "@/models/Report";
import { sum } from "@/views/utility";
import Base from "../Base";
import Revenue from "./components/Revenue.vue";
import Summary from "./components/Summary.vue";
import WifiStatus from "./components/WifiStatus.vue";
import Transactions from "./components/Transactions.vue";
import Statistics from "./components/Statistics.vue";
let default_1 = class default_1 extends Base {
    constructor() {
        super(...arguments);
        this.data = null;
        this.markMode = false;
    }
    mounted() {
        this.load();
    }
    get kiosk() {
        return this.data.MerchantKiosk.Kiosk;
    }
    get title() {
        if (!this.data)
            return "";
        return this.data.MerchantKiosk.Name;
    }
    get canSetTestData() {
        if (!this.data)
            return false;
        const mk = this.data.MerchantKiosk;
        return mk.IsActive && (mk.Type == MerchantKioskType.admin || mk.Type == MerchantKioskType.moderator);
    }
    get statistics() {
        let result = [];
        if (!this.data)
            return result;
        for (let i = 0; i < 24; i += 2) {
            result.push({
                title: `${pad(i)}:00 - ${pad(i + 2)}:00`,
                play: 0,
                gift: 0,
            });
        }
        for (let t of this.data.Transactions) {
            if (this.markMode ? t.isTestData : t.IsTestData)
                continue;
            let h = Math.floor(t.Time.getHours() / 2);
            if (t.Type == KioskTransactionType.Giftout)
                result[h].gift++;
            else
                result[h].play += t.Amount;
        }
        return result;
    }
    load() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = null;
            try {
                this.data = yield post("/api/Report/Daily", {
                    Id: this.id,
                    Start: this.date,
                });
                this.update = now();
            }
            catch (e) {
                if (e instanceof Error)
                    yield this.alert(e.message, "讀取失敗", "error");
                this.$router.back();
            }
        });
    }
    toMarkMode() {
        if (!this.data)
            return;
        this.data.Transactions.forEach(t => Vue.set(t, "isTestData", t.IsTestData));
        this.markMode = true;
    }
    get markSummary() {
        var _a;
        if (!this.data)
            return [];
        const transactions = ((_a = this.data.Transactions) !== null && _a !== void 0 ? _a : []).filter(t => !t.isTestData);
        return this.data.Reports.map(r => {
            const trans = transactions.filter(t => r.StartTime <= t.Time && t.Time < r.EndTime);
            const giftout = sum(trans.filter(t => t.Type == KioskTransactionType.Giftout), t => t.Amount);
            const total = sum(trans.filter(t => t.Type != KioskTransactionType.Giftout), t => t.Amount);
            return {
                StartTime: r.StartTime,
                EndTime: r.EndTime,
                Price: r.Price,
                Giftout: giftout,
                Total: total,
                Coin: r.Coin,
                Remote: r.Remote,
                ProfitRate: 0,
                Profit: r.Price === undefined ? 0 : total * 10 - r.Price * giftout,
            };
        });
    }
    get markRevenue() {
        var _a, _b, _c, _d;
        const result = JSON.parse(JSON.stringify((_b = (_a = this.data) === null || _a === void 0 ? void 0 : _a.Revenues) !== null && _b !== void 0 ? _b : []));
        for (const r of result) {
            if (r.Name == "現金投幣") {
                r.Amount = ((_d = (_c = this.data) === null || _c === void 0 ? void 0 : _c.Transactions) !== null && _d !== void 0 ? _d : [])
                    .filter(t => !t.isTestData && t.Type == KioskTransactionType.Coin)
                    .reduce((v, t) => v + t.Amount, 0);
            }
        }
        return result;
    }
    mark() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.data)
                return;
            try {
                const request = {
                    HardwareId: this.data.MerchantKiosk.Kiosk.HardwareId,
                    Events: [],
                };
                for (const t of this.data.Transactions) {
                    if (t.isTestData != t.IsTestData) {
                        request.Events.push({
                            Type: t.Type == KioskTransactionType.Coin ? "CoinEvent" : "GiftoutEvent",
                            Time: t.Time.toISOString(),
                            Enable: !t.isTestData,
                        });
                    }
                }
                if (!(yield post("/api/Report/ModifyTestData", Object.assign({}, request))))
                    throw new Error("未知錯誤");
                this.data.Giftout = sum(this.markSummary, r => r.Giftout);
                this.data.Total = sum(this.markSummary, r => r.Total);
                this.data.Revenues = this.markRevenue;
                for (const t of this.data.Transactions)
                    t.IsTestData = t.isTestData;
            }
            catch (e) {
                if (e instanceof Error)
                    this.alert(e.message, "儲存失敗", "error");
            }
            finally {
                this.markMode = false;
            }
        });
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
__decorate([
    Inject()
], default_1.prototype, "confirm", void 0);
__decorate([
    Prop(String)
], default_1.prototype, "id", void 0);
__decorate([
    Prop(String)
], default_1.prototype, "date", void 0);
default_1 = __decorate([
    Component({ components: { Revenue, Summary, Statistics, Transactions, WifiStatus } })
], default_1);
export default default_1;
