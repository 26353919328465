var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as bootstrap from 'bootstrap';
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.value = "";
        this.tag = null;
    }
    mounted() {
        this.modal = new bootstrap.Modal(this.$el);
    }
    get others() {
        return this.tags.filter(t => { var _a; return t != ((_a = this.tag) === null || _a === void 0 ? void 0 : _a.Name); });
    }
    show(t) {
        this.tag = t;
        this.value = t.Name;
        this.modal.show();
    }
    ok() {
        return __awaiter(this, void 0, void 0, function* () {
            this.modal.hide();
            yield post('/api/tag/edit', { Id: this.tag.Id, Name: this.value });
            // eslint-disable-next-line require-atomic-updates
            this.tag.Name = this.value;
        });
    }
};
__decorate([
    Prop()
], default_1.prototype, "tags", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
