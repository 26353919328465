
	/* eslint-disable @typescript-eslint/no-magic-numbers */
	import { Component, Prop, Vue } from "vue-property-decorator";
	import { KioskTransactionType } from "@/models/Report";
	import type { KioskTransaction } from "@/models/Report";

	export interface TransactionGroup {
		time: Date;
		type: KioskTransactionType;
		open: boolean;
		sub?: TransactionGroup[];
		items?: KioskTransaction[];
	}

	const GAP = 15 * 60 * 1000;

	/** 把所有的啟動都視為是投幣，以簡化分類 */
	function normalizeType(type: KioskTransactionType): KioskTransactionType {
		if(type == KioskTransactionType.Remote ||
			type == KioskTransactionType.Payment ||
			type == KioskTransactionType.GiftCoin ||
			type == KioskTransactionType.EventTicket ||
			type == KioskTransactionType.TrialPlay
		) {
			type = KioskTransactionType.Coin;
		}
		return type;
	}

	@Component
	export default class extends Vue {
		@Prop() public data!: KioskTransaction[];
		@Prop() public markMode?: boolean;

		public groups: TransactionGroup[] = [];

		created(): void {
			let lastTime: Date = new Date(2000, 1, 1);
			for(let t of this.data) {
				let lastGroup = this.groups[this.groups.length - 1];
				let type = normalizeType(t.Type);
				const isTest = this.markMode ? t.isTestData : t.IsTestData;
				if(type != lastGroup?.type || type == KioskTransactionType.Giftout) { // 修改：出獎一律顯示成不同資料
					this.groups.push({
						time: t.Time,
						type,
						open: false,
						items: [t],
					});
				} else {
					lastGroup.items?.push(t);
				}

				lastGroup = this.groups[this.groups.length - 1];
				if(type == KioskTransactionType.Coin) {
					lastGroup.sub ??= [];
					if(t.Time.getTime() - lastTime.getTime() > GAP) {
						lastGroup.sub.push({
							time: t.Time,
							type,
							open: false,
							items: [t],
						});
					} else {
						let lastSub = lastGroup.sub[lastGroup.sub.length - 1];
						lastSub.items!.push(t);
					}
					lastTime = t.Time;
				} else {
					lastTime = new Date(2000, 1, 1);
				}
				Vue.set(t, "open", false);
			}
			for(let g of this.groups) {
				if(g.sub) {
					for(let s of g.sub) {
						if(s.items) s.items.sort((a, b) => a.Time.getTime() - b.Time.getTime());
					}
				}
			}
		}

		protected getTotal(g: TransactionGroup): number {
			return (g.items ?? []).reduce((v, t) => {
				const isTest = this.markMode ? t.isTestData : t.IsTestData;
				return v + (isTest ? 0 : t.Amount);
			}, 0);
		}

		protected getText(g: TransactionGroup): string {
			if(g.type == KioskTransactionType.Coin) return "累積啟動";
			return "出獎";
		}

		protected trClass(g: TransactionGroup): string {
			if(g.type == KioskTransactionType.Giftout) return "text-info";
			return "";
		}

		protected typeText(t: KioskTransaction): string {
			if(t.Type == KioskTransactionType.GiftCoin) return "投Ｏ送Ｘ累保";
			if(t.Type == KioskTransactionType.EventTicket) return "活動券";
			if(t.Type == KioskTransactionType.Coin) return "現金投幣";
			if(t.Type == KioskTransactionType.TrialPlay) return "投Ｏ送Ｘ不累保";
			if(t.Type == KioskTransactionType.Payment) return t.Payments[0].Name; // "多元支付";
			return "遠端投幣";
		}
	}
