var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Component, Prop, Vue } from "vue-property-decorator";
import { KioskTransactionType } from "@/models/Report";
const GAP = 15 * 60 * 1000;
/** 把所有的啟動都視為是投幣，以簡化分類 */
function normalizeType(type) {
    if (type == KioskTransactionType.Remote ||
        type == KioskTransactionType.Payment ||
        type == KioskTransactionType.GiftCoin ||
        type == KioskTransactionType.EventTicket ||
        type == KioskTransactionType.TrialPlay) {
        type = KioskTransactionType.Coin;
    }
    return type;
}
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.groups = [];
    }
    created() {
        var _a, _b;
        let lastTime = new Date(2000, 1, 1);
        for (let t of this.data) {
            let lastGroup = this.groups[this.groups.length - 1];
            let type = normalizeType(t.Type);
            const isTest = this.markMode ? t.isTestData : t.IsTestData;
            if (type != (lastGroup === null || lastGroup === void 0 ? void 0 : lastGroup.type) || type == KioskTransactionType.Giftout) { // 修改：出獎一律顯示成不同資料
                this.groups.push({
                    time: t.Time,
                    type,
                    open: false,
                    items: [t],
                });
            }
            else {
                (_a = lastGroup.items) === null || _a === void 0 ? void 0 : _a.push(t);
            }
            lastGroup = this.groups[this.groups.length - 1];
            if (type == KioskTransactionType.Coin) {
                (_b = lastGroup.sub) !== null && _b !== void 0 ? _b : (lastGroup.sub = []);
                if (t.Time.getTime() - lastTime.getTime() > GAP) {
                    lastGroup.sub.push({
                        time: t.Time,
                        type,
                        open: false,
                        items: [t],
                    });
                }
                else {
                    let lastSub = lastGroup.sub[lastGroup.sub.length - 1];
                    lastSub.items.push(t);
                }
                lastTime = t.Time;
            }
            else {
                lastTime = new Date(2000, 1, 1);
            }
            Vue.set(t, "open", false);
        }
        for (let g of this.groups) {
            if (g.sub) {
                for (let s of g.sub) {
                    if (s.items)
                        s.items.sort((a, b) => a.Time.getTime() - b.Time.getTime());
                }
            }
        }
    }
    getTotal(g) {
        var _a;
        return ((_a = g.items) !== null && _a !== void 0 ? _a : []).reduce((v, t) => {
            const isTest = this.markMode ? t.isTestData : t.IsTestData;
            return v + (isTest ? 0 : t.Amount);
        }, 0);
    }
    getText(g) {
        if (g.type == KioskTransactionType.Coin)
            return "累積啟動";
        return "出獎";
    }
    trClass(g) {
        if (g.type == KioskTransactionType.Giftout)
            return "text-info";
        return "";
    }
    typeText(t) {
        if (t.Type == KioskTransactionType.GiftCoin)
            return "投Ｏ送Ｘ累保";
        if (t.Type == KioskTransactionType.EventTicket)
            return "活動券";
        if (t.Type == KioskTransactionType.Coin)
            return "現金投幣";
        if (t.Type == KioskTransactionType.TrialPlay)
            return "投Ｏ送Ｘ不累保";
        if (t.Type == KioskTransactionType.Payment)
            return t.Payments[0].Name; // "多元支付";
        return "遠端投幣";
    }
};
__decorate([
    Prop()
], default_1.prototype, "data", void 0);
__decorate([
    Prop()
], default_1.prototype, "markMode", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
